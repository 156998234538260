<template>
  <el-popover
      :placement="placement"
      title=""
      trigger="hover">
    <!-- 放大的图片 -->
    <el-image style="max-height: 18rem; max-width: 18rem;display: block" :src="imgUrl?imgUrl:url"/>
    <!-- 表格中的 -->
    <img slot="reference" style="width: 30px;height: 30px;border-radius: 50%" :style="'width:'+(header?'88px;': '30px;') + 'height:'+(header?'88px;': '30px')" class="img-lg" :src="imgUrl?imgUrl:url" :alt="imgUrl?imgUrl:url" >
  </el-popover>
</template>

<script>
export default {
  name: "pop_image",
  data() {
    return{
      url: require("../../assets/img/avatar.png"),
      width: '88px',
      height: '88px',
    }
  },
  props:{
    imgUrl: {type: String, required: true},
    placement: {type: String, required: true},
    header: {type: String},
  },
}
</script>

<style scoped>

</style>
