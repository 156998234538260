<template>
  <el-card>
    <div id="rank-chart" style="margin-bottom: 36px">
      <div><img @click="$router.push('/user_center')" style="width: 24px;height: 24px" src="../../assets/img/user/phone.svg" alt=""><div>{{$t('user_center.total_earn_rank_r')}}</div></div>
      <div>{{$t('user_center.my_rank')}}：
        <span v-if="userEarnInfoRank.rankNum>450">{{$t('user_center.no_listed')}}</span>
        <span v-else-if="userEarnInfoRank.rankNum>3||userEarnInfoRank.rankNum=='未入榜'">{{userEarnInfoRank.rankNum}}</span>
        <span v-else style="color: #E3B936;">NO.{{userEarnInfoRank.rankNum}}<img style="margin-left: 4px" src="../../assets/img/crown.svg" alt=""></span>
      </div>
      <div class="rank-add" v-for="(item,index) in profitTop10">
        <img v-if="index===0" src="../../assets/img/user/no1.svg" alt="">
        <img v-if="index===1" src="../../assets/img/user/no2.svg" alt="">
        <img v-if="index===2" src="../../assets/img/user/no3.svg" alt="">
        <div v-if="index>2" style="text-align: center;width: 18px;font-size: 12px;font-weight: 600;color: #000613;">{{index+1}}</div>
        <div style="margin-left: 10px">
          <img style="width: 30px;height: 30px" v-if="item.user_face==null" @click="showImage()" src="../../assets/img/avatar.png" alt="">
          <img style="width: 30px;height: 30px; border-radius: 50%" v-else :src="item.user_face" @click="showImage(item.user_face)" alt="">
<!--          <pop_image style="" v-if="item.user_face==null" :imgUrl="''" :placement="'right'" alt=""/>-->
<!--          <pop_image style="" v-else :imgUrl="item.user_face" :placement="'right'" alt=""/>-->
        </div>
        <span class="name">
          <span style="display:block;">{{item.mobile}}</span>
          <span style="display: block;text-align: left">{{item.name}}</span>
        </span>
<!--        <img @click="$router.push('/grant_member')" v-if="item.vip_type===3" style="cursor: pointer" src="../../assets/img/ssvip.svg" alt="">-->
<!--        <img @click="$router.push('/grant_member')" v-if="item.vip_type===2" style="cursor: pointer" src="../../assets/img/SVIP.svg" alt="">-->
<!--        <img @click="$router.push('/grant_member')" v-if="item.vip_type===1" style="cursor: pointer" src="../../assets/img/VIP.svg" alt="">-->
        <span v-if="index<3" class="profit" style="color: #E3B936">{{(item.profit*1).toFixed(2)}}</span>
        <span v-else class="profit">{{(item.profit*1).toFixed(2)}}</span>
      </div>
    </div>
    <el-image-viewer v-if="imgsVisible" :urlList="[showImgUrl]" :on-close="closeViewer"></el-image-viewer>
  </el-card>
</template>

<script>
import {getMyRank, getProfitTop10} from "../../server/user";
import Pop_image from "../common/pop_image";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  name: "earn_rank",
  components: {ElImageViewer},
  data(){
    return{
      profitTop10:[],
      userEarnInfoRank:{
        profit: 0,
        rankNum: this.$t('user_center.no_listed'),
        user_id: 0,
      },
      imgsVisible: false,
      showImgUrl: '',
    }
  },
  created() {
    this.getProfitTop10()
    getMyRank().then(res=>{
      if (res.data===null) return
      this.userEarnInfoRank=res.data
    })

  },
  methods:{
    async getProfitTop10(){
      const res=await getProfitTop10()
      this.profitTop10=res.data
    },
    async getMyRank(){
      const myRank=await getMyRank()
      if (myRank.data===null) return
      this.userEarnInfoRank=myRank.data
    },
    showImage(url){
      this.showImgUrl = url ? url : require("../../assets/img/avatar.png")
      this.imgsVisible = true
      this.$nextTick(()=>{
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__img");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      })
    },
    closeViewer(){
      this.imgsVisible = false
    },
  }
}
</script>

<style scoped>
#rank-chart>div:first-child{
  margin: 20px 0 34px 0;
  display: flex;
  align-items: center;
}
#rank-chart>div:first-child>div:nth-child(2){
  font-size: 19px;
  font-weight: 500;
  color: #000613;
  margin-left: 8px;
}
#rank-chart>div:nth-child(2){
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
  margin-bottom: 11px;
}
/*累计收益排行add*/
#rank-chart{
  margin:0px;
  padding:0px;
}
.rank-add{
  height: 30px;
  display: flex !important;
  align-items: center;
  padding: 9px 0 6px 0 ;
  margin: 0 !important;
  border-bottom: 1px solid rgba(65, 97, 128, 0.15);
  position: relative;
}
.rank-add img:first-child{
  width: 18px;
  height: 26px;
}
.rank-add span.name{
  font-size: 14px;
  font-weight: 400;
  color: #000613;
  margin: 0 8px 0 12px;
}
.rank-add img:nth-child(3){
  /*width: 16px;*/
  /*height: 16px;*/
}
.rank-add span.profit{
  font-size: 14px;
  font-weight: 600;
  color: #314659;
  position: absolute;
  right: 0;
}
@media screen and (max-width: 1397px){
  #rank-chart{
    column-gap: 15px;
  }
}
</style>
